/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "../components/footer";
import "./layout.css";

import moment from "moment-timezone";
moment.tz.setDefault("Europe/Athens");

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      {/* <div className="container"> */}
      <main
        className={`${
          moment().isBetween(
            moment("2021-11-25 00:00:00"),
            moment("2021-11-26 23:59:59")
          )
            ? "offer"
            : ""
        }`}
      >
        {children}
      </main>
      {/* </div> */}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
