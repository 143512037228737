import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";

const Footer = () => {
  const [lang, setLang] = React.useState(
    typeof window !== "undefined"
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "gr"
      : "gr"
  );
  const data = useStaticQuery(graphql`
    query {
      allLabels {
        edges {
          node {
            label
            gr
            en
          }
        }
      }
    }
  `);

  const description = data.allLabels.edges.find(
    ed => ed.node.label === "footer-text-1"
  );
  const menuVitamin = data.allLabels.edges.find(
    ed => ed.node.label === "menu-vitamin"
  );
  const articlesMenu = data.allLabels.edges.find(
    ed => ed.node.label === "articles-menu"
  );

  const about = data.allLabels.edges.find(
    ed => ed.node.label === "about-footer-menu"
  );

  const faq = data.allLabels.edges.find(
    ed => ed.node.label === "faq-footer-menu"
  );

  const ftext2 = data.allLabels.edges.find(
    ed => ed.node.label === "footer-text-2"
  );

  const ftext3 = data.allLabels.edges.find(
    ed => ed.node.label === "footer-text-3"
  );

  const termCondn = data.allLabels.edges.find(
    ed => ed.node.label === "term-condition"
  );
  const privacyPoilcy = data.allLabels.edges.find(
    ed => ed.node.label === "menu-policy"
  );


  return (
    <div className="container">
      <footer style={{ marginLeft: "0", marginRight: "0" }}>
        <div className="hr hrfull">&nbsp;</div>
        <div className="footerLinks" style={{ margin: "40px 0 20px" }}>
          <div className="footerLeft">
            <Link to="/">
              <img alt="Great logo" src="/black_2.svg" className="footerLogo" />
            </Link>
            <p className="footerText">{description.node[lang]}</p>
            <p className="flag">
              <img alt="Greek flag" src="/greek.svg" />
            </p>
          </div>
          <div className="footerRight">
            <div className="footerLinksLeft">
              <Link to="/vitamin">{menuVitamin.node[lang]}</Link>
              <Link to="/blog">{articlesMenu.node[lang]}</Link>
              <Link to="/vitamin#faq">{faq.node[lang]}</Link>
              <Link to="/about">{about.node[lang]}</Link>
            </div>
            {/* <div className="footerLinksRight">
            <Link>Σχετικά με μας</Link>
            <Link>Κατεβάστε το app</Link>
            <Link>Συχνές ερωτήσεις</Link>
            <Link>Blog</Link>
          </div> */}
          </div>
        </div>
        <div className="hr hrfull">&nbsp;</div>
        <div className="footerLinks footerLast">
          <div className="footerLeft footerTerms">
            <a>© {new Date().getFullYear()} ΒΑΦΑΡΜ ΙΚΕ</a>
            <Link to="/terms">{termCondn && termCondn.node[lang]}</Link>
            <Link to="/privacy">{privacyPoilcy && privacyPoilcy.node[lang]}</Link>
          </div>
          <div className="footerRight footerInfo">
            <div className="eoflogo">
              <img alt="eof logo" src="/eof.svg" />
              <div className="disclaimer">
                <p>{ftext2.node[lang]}</p>

                <p>{ftext3.node[lang]}</p>
              </div>
            </div>
          </div>
        </div>
        <img
          alt="footer cross"
          src="/footercross.svg"
          className="footercross"
        />
      </footer>
    </div>
  );
};

export default Footer;
