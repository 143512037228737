import { graphql, Link, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { Fragment, useRef, useState } from "react";
import moment from "moment-timezone";
moment.tz.setDefault("Europe/Athens");

const Header = ({ siteTitle }) => {
  const [lang, setLang] = React.useState(
    typeof window !== "undefined"
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "gr"
      : "gr"
  );
  const data = useStaticQuery(graphql`
    query {
      allLabels {
        edges {
          node {
            label
            gr
            en
          }
        }
      }
    }
  `);
  const [open, setOpen] = useState(false);
  const [terms, showTerms] = useState(false);
  const bannerClick = useRef(null);

  const gluten = data.allLabels.edges.find(
    ed => ed.node.label === "top-bar-text"
  );

  const vitamin = data.allLabels.edges.find(
    ed => ed.node.label === "menu-vitamin"
  );
  const articles = data.allLabels.edges.find(
    ed => ed.node.label === "articles-menu"
  );

  const menu_chat_with_us = data.allLabels.edges.find(
    ed => ed.node.label === "menu_chat_with_us"
  );

  const profile = data.allLabels.edges.find(
    ed => ed.node.label === "profile-menu"
  );

  const menuFaq = data.allLabels.edges.find(
    ed => ed.node.label === "menu-faq"
  );



  const shop = data.allLabels.edges.find(
    ed => ed.node.label === "menu_shop"
  );

  return (
    <Fragment>
      <div className="infoBar">
        <div className="container">
          <div className="header-top-bar">
            <div style={{ position: "fixed" }}>
              {gluten.node[lang]}
            </div>
            <span style={{ marginLeft: "auto" }}>
              <a
                className={lang === "gr" ? "lang-active" : ""}
                href=""
                style={{ color: "white", textDecoration: "none", padding: "0 8px" }}
                onClick={e => {
                  e.preventDefault();
                  if (typeof window !== "undefined") {
                    localStorage.setItem("lang", "gr");
                    window.location.reload();
                  }
                }}
              >
                GR
              </a>{" "}
              |{" "}
              <a
                className={lang === "en" ? "lang-active" : ""}
                href=""
                style={{ color: "white", textDecoration: "none", padding: "0 0 0 8px" }}
                onClick={e => {
                  e.preventDefault();
                  if (typeof window !== "undefined") {
                    localStorage.setItem("lang", "en");
                    window.location.reload();
                  }
                }}
              >
                EN
              </a>
            </span>
          </div>
        </div>

      </div>
      <header className={open ? "fixedPos" : ""}>
        <div className="container head">
          {/* <Link to="/">{siteTitle}</Link> */}
          <div
            className="chaticon mobmenu mobileOnly"
            onClick={e => {
              window.Intercom("show");
              e.preventDefault();
            }}
          >
            <img alt="chat icon" src="/chat.svg" />
          </div>
          <div className="logo mobmenu" style={{ paddingLeft: "0" }}>
            <Link to="/">
              <img
                className="logoGreat"
                alt="Great for Women"
                src="/great.svg"
              />
              <img
                className="logoWomen"
                alt="Great for Women"
                src="/for-women.svg"
              />
            </Link>
          </div>
          <div className="navLinks desktopOnly mobmenu" style={{ paddingRight: "0" }}>
            <a href={process.env.GATSBY_API_URL}>
              {shop && shop.node[lang]}
            </a>
            <Link to="/vitamin">{vitamin.node[lang]}</Link>
            <Link to="/blog">{articles.node[lang]}</Link>
            <a
              style={{ cursor: "pointer" }}
              onClick={e => {
                window.Intercom("show");
                e.preventDefault();
              }}
            >
              {menu_chat_with_us.node[lang]}
            </a>
            <a href={lang === "gr" ? `${process.env.GATSBY_API_URL}/my-profile` : `${process.env.GATSBY_API_URL}/en/my-profile`} target="_blank">
              {profile.node[lang]}
            </a>
          </div>
          <div
            className="menuicon mobmenu mobileOnly"
            onClick={() => setOpen(!open)}
          >
            {open === false ? (
              <>
                <span className="mobmenuItems">Menu</span>{" "}
                <img alt="menu icon" className="mobmenuItems" src="/menu.svg" />
              </>
            ) : (
              <>
                <span className="mobmenuItems">Close</span>{" "}
                <img
                  alt="close menu icon"
                  className="mobmenuItems"
                  src="/close.svg"
                />
              </>
            )}
          </div>
        </div>
      </header>
      <div
        className={`infoBar discountBar ${terms && "discountExpand"}`}
        style={{
          display: moment().isBetween(
            moment("2021-11-25 00:00:00"),
            moment("2021-11-26 23:59:59")
          )
            ? "flex"
            : "none",
        }}
        onClick={e => bannerClick.current.click()}
      >
        <a
          href="https://account.greatforwomen.com/checkout?ref=bf"
          target="_blank"
          style={{ display: "none" }}
          ref={bannerClick}
        ></a>
        <div>
          BLACK FRIDAY SALE: <strong>1+1 ΔΩΡΟ</strong> &amp; 4ος ΜΗΝΑΣ ΔΩΡΟ{" "}
          <br className="mobileOnly" /> Μόνο για <strong>24 ωρες</strong>
          <span className="desktopOnly">&nbsp;&nbsp;-&nbsp;&nbsp;</span>
        </div>
        <span className="smallText">
          Η προσφορά λήγει στις 23:59, 26/11/2021{" "}
          <a
            style={{ textDecoration: "underline" }}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              showTerms(!terms);
            }}
          >
            {terms ? "Λιγότερα" : "Περισσότερα"}
          </a>
        </span>
        <div
          className="flexbreak"
          style={{ display: terms ? "block" : "none" }}
        ></div>
        <div
          className="offerTerms smallText"
          style={{ display: terms ? "block" : "none" }}
        >
          Η προσφορά ισχύει στις 26/11/2021 μέχρι τις 23:59. Αφορά μόνο νέες
          συνδρομές (μηνιαίες ή εξάμηνες). Με κάθε ενεργοποίηση νέας συνδρομής,
          το δώρο (1 μπουκάλι Great πολυβιταμίνες / 60 κάψουλες) θα προστεθεί
          στην παραγγελία σας. Στην 4η αυτόματη ανανέωση συνδρομής θα λάβετε ένα
          μπουκαλάκι δωρεάν. Τα δωρεάν μεταφορικά ισχύουν για Ελλάδα (μηνιαία
          και εξάμηνη συνδρομή) και εξωτερικό (μόνο για εξάμηνη συνδρομή στις
          χώρες της Ε.Ε). Η προσφορά δεν μπορεί να συνδυαστεί με άλλες εκπτώσεις
          και προωθητικές ενέργειες. Διατηρούμε το δικαίωμα να διακόψουμε την
          προσφορά για οποιοδήποτε λόγο.
        </div>
      </div>
      <div id="mobileOverlay" style={{ display: open ? "block" : "none" }}>
        <a href={process.env.GATSBY_API_URL}>
          {shop && shop.node[lang]}
        </a>
        <Link to="/vitamin">{vitamin.node[lang]}</Link>
        <Link to="/blog">{articles.node[lang]}</Link>
        <a
          onClick={e => {
            window.Intercom("show");
            e.preventDefault();
          }}
        >
          {menu_chat_with_us.node[lang]}
        </a>
        <Link to="/vitamin#faq">{menuFaq && menuFaq.node[lang]}</Link>
        <a href={`${process.env.GATSBY_API_URL}/my-profile`} target="_blank">
          {profile.node[lang]}
        </a>
        <div className="follow">
          <img alt="facebook logo" src="/facebook-logo-white.svg" />
          <span>facebook.com/greatforwomen</span>
          <span className="spacer"></span>
          <br className="brhidden" />
          <p className="brhidden" style={{ height: "0px" }}></p>
          <img alt="instagram icon" src="/instagram-logo-white.svg" />
          <span>@greatforwomen</span>
        </div>
        <div className="follow">

          <span onClick={e => {
            e.preventDefault();
            if (typeof window !== "undefined") {
              localStorage.setItem("lang", "gr");
              window.location.reload();
            }
          }} className={lang === "gr" ? "lang-active" : ""} style={{ padding: "0 10px" }}>GR</span>
          |{" "}

          <span onClick={e => {
            e.preventDefault();
            if (typeof window !== "undefined") {
              localStorage.setItem("lang", "en");
              window.location.reload();
            }
          }} className={lang === "en" ? "lang-active" : ""} style={{ padding: "0 0 0 10px" }}>EN</span>
        </div>
      </div>
    </Fragment>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
